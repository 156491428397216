import React from "react";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "shadcnuiComponents/ui/popover";

export default function Popup({
    popupTrigger,
    popupContent,
}: {
    popupTrigger: React.ReactNode;
    popupContent: React.ReactNode;
}) {
    return (
        <Popover>
            <PopoverTrigger className="flex gap-4 items-center py-4 px-6 shadow-sm shadow-neutral-300 rounded-lg font-semibold active:shadow-inner active:shadow-neutral-400">
                {popupTrigger}
            </PopoverTrigger>
            <PopoverContent className="flex flex-col gap-4 p-6">
                {popupContent}
            </PopoverContent>
        </Popover>
    );
}
