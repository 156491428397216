import Footer from "components/Footer";
import Title from "components/Title";
import React from "react";
import { BsInstagram, BsWhatsapp, BsTelephone } from "react-icons/bs";
import { FiMail } from "react-icons/fi";

export default function ContatosScreen() {
    const contacts = [
        {
            id: 1,
            name: "Instagram",
            url: "https://www.instagram.com/unicapasoficial/",
            description: "@unicapasoficial",
            component: <BsInstagram />,
        },
        {
            id: 2,
            name: "WhatsApp",
            url: "https://wa.me/5585986821543",
            description: "+55 (85) 98682-1543",
            component: <BsWhatsapp />,
        },
        {
            id: 3,
            name: "Telefone",
            description: "3771-9194",
            component: <BsTelephone />,
        },
        {
            id: 4,
            name: "E-mail",
            url: "mailto:unicapas@hotmail.com",
            description: "unicapas@hotmail.com",
            component: <FiMail />,
        },
    ];

    return (
        <div className="mt-[20vh] animate-appear duration-300">
            <Title className="text-black sm:text-2xl md:text-3xl ">
                Fale conosco
            </Title>
            <ul className="sm:h-[50vh] sm:mb-[10vh] md:h-[65vh] w-[90%] mx-auto flex flex-col justify-center gap-6 items-center">
                {contacts.map((item) => (
                    <li>
                        <a href={item.url} className="flex gap-3 items-center">
                            <div className="font-bold">{item.component}</div>
                            <h2 className="font-semibold">{item.name}: </h2>
                            <p className="underline font-medium">
                                {item.description}
                            </p>
                        </a>
                    </li>
                ))}
            </ul>
            <Footer theme="dark" />
        </div>
    );
}
