import React, { useEffect } from "react";
import ProdutosScreen from "screens/ProdutosScreen";

export default function Produtos() {
    useEffect(() => {
        document.title = "Unicapas | Nossos produtos";
    }, []);

    return <ProdutosScreen />;
}
