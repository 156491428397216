import Footer from "components/Footer";
import Title from "components/Title";
import { MainImagesType, mainImages } from "assets/images";
import Card from "components/Card";
import { FiFilter } from "react-icons/fi";
import { AiOutlineCloseCircle, AiOutlineUnorderedList } from "react-icons/ai";
import { MdOutlineSearchOff } from "react-icons/md";
import { categories } from "assets/categories";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "shadcnuiComponents/ui/select";
import { RadioGroup, RadioGroupItem } from "shadcnuiComponents/ui/radio-group";
import { Label } from "shadcnuiComponents/ui/label";
import Popup from "components/Popup";
import React, { useEffect, useState } from "react";
import { useSearchContext } from "context/searchContext";
import Container404 from "components/Container404";

export default function ProdutosScreen() {
    const [filter, setFilter] = useState("default");
    const [order, setOrder] = useState("default");
    const { search, changeSearch } = useSearchContext();

    const imageHandler = (arr: MainImagesType[]) => {
        let newArr = arr;

        if (order !== "default") {
            switch (order) {
                case "a-z":
                    newArr = newArr.sort((a, b) =>
                        a.label < b.label ? -1 : 1
                    );
                    break;
                case "z-a":
                    newArr = newArr.sort((a, b) =>
                        a.label > b.label ? -1 : 1
                    );
                    break;
                case "mais-modelos":
                    newArr = newArr.sort((a, b) =>
                        a.options > b.options ? -1 : 1
                    );
                    break;
            }
        } else {
            newArr = arr;
        }

        if (filter) {
            if (filter === "default") {
                newArr = arr;
            } else {
                newArr = newArr.filter((item) => item.category === filter);
            }
        }

        if (search) {
            newArr = newArr.filter((item) =>
                item.label.toLowerCase().includes(search.toLowerCase())
            );
        }

        return newArr;
    };

    useEffect(() => {
        imageHandler(mainImages);
    }, [filter, order, search]);

    return (
        <div className="mt-[20vh] animate-appear duration-300">
            <Title className="sm:text-2xl md:text-3xl">Nosso catálogo</Title>
            <div className="flex justify-between w-4/5 mx-auto mt-6 mb-4">
                <Popup
                    popupTrigger={
                        <>
                            <p>Filtrar</p>
                            <FiFilter />
                        </>
                    }
                    popupContent={
                        <>
                            <p className="font-semibold">Por categoria: </p>
                            <Select
                                onValueChange={(e) => setFilter(e)}
                                defaultValue={filter}
                            >
                                <SelectTrigger className="capitalize">
                                    <SelectValue placeholder="Categoria" />
                                </SelectTrigger>
                                <SelectContent defaultValue={filter}>
                                    <>
                                        <SelectItem
                                            value="default"
                                            className="capitalize"
                                        >
                                            Todos
                                        </SelectItem>
                                        {categories.map((item, index) => (
                                            <SelectItem
                                                key={index}
                                                value={item}
                                                className="capitalize"
                                            >
                                                {item}
                                            </SelectItem>
                                        ))}
                                    </>
                                </SelectContent>
                            </Select>
                        </>
                    }
                />

                <Popup
                    popupTrigger={
                        <>
                            <AiOutlineUnorderedList />
                            <p>Ordenar</p>
                        </>
                    }
                    popupContent={
                        <>
                            <RadioGroup
                                defaultValue={order}
                                onValueChange={(e) => setOrder(e)}
                            >
                                <div>
                                    <RadioGroupItem
                                        value="default"
                                        id="default"
                                    />
                                    <Label htmlFor="default" className="ml-2">
                                        Padrão
                                    </Label>
                                </div>
                                <div>
                                    <RadioGroupItem value="a-z" id="a-z" />
                                    <Label htmlFor="a-z" className="ml-2">
                                        A-Z
                                    </Label>
                                </div>
                                <div>
                                    <RadioGroupItem value="z-a" id="z-a" />
                                    <Label htmlFor="z-a" className="ml-2">
                                        Z-A
                                    </Label>
                                </div>
                                <div>
                                    <RadioGroupItem
                                        value="mais-modelos"
                                        id="mais-modelos"
                                    />
                                    <Label
                                        htmlFor="mais-modelos"
                                        className="ml-2"
                                    >
                                        Mais modelos
                                    </Label>
                                </div>
                            </RadioGroup>
                        </>
                    }
                />
            </div>
            {imageHandler(mainImages).length > 0 && (
                <div className="flex flex-col md:flex-row items-center w-fit mx-auto gap-2 text-sm md:text-md">
                    Exibindo todos os resultados {search && `para "${search}"`}
                    {filter !== "default" && (
                        <p>
                            do tipo{" "}
                            <span className="capitalize">"{filter}"</span>
                        </p>
                    )}
                    {order !== "default" && (
                        <p>
                            ordenados por{" "}
                            <span className="capitalize">
                                "
                                {order !== "a-z" && order !== "z-a"
                                    ? order.replace("-", " ")
                                    : order}
                                "
                            </span>
                        </p>
                    )}
                    {search || filter !== "default" || order !== "default" ? (
                        <AiOutlineCloseCircle
                            className="hover: cursor-pointer"
                            onClick={() => {
                                changeSearch({ search: "" });
                                setFilter("default");
                                setOrder("default");
                            }}
                        />
                    ) : (
                        ""
                    )}
                </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-11/12 mx-auto my-8 min-h-[40vh]">
                {imageHandler(mainImages).length > 0 ? (
                    imageHandler(mainImages).map((item) => (
                        <Card item={item} key={item.id} />
                    ))
                ) : (
                    <div className="justify-self-center place-content-center col-start-1 col-span-3 h-fit my-auto">
                        <Container404 />
                    </div>
                )}
            </div>
            <Footer theme="dark" />
        </div>
    );
}
