import Footer from "components/Footer";
import Swiper from "components/Swiper";
import Title from "components/Title";
import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { homeImages } from "assets/images";

export default function HomeScreen() {
    return (
        <div className="bg-black md:pt-32 sm:pt-16 relative z-10 min-h-screen w-screen animate-appear duration-500">
            <img
                src="/images/Home/home-pic.png"
                alt=""
                className="sm:w-full md:w-3/4 mx-auto mt-[10%]"
            />
            <div className="bg-zinc-950 w-screen pt-4 pb-6">
                <Title className="text-white w-full text-center mt-8 pb-6 sm:text-xl md:text-2xl ">
                    Confira abaixo alguns de nossos produtos!
                </Title>
                <Swiper
                    spaceBetween={window.outerWidth > 320 ? 30 : 10}
                    slidesPerView={2.7}
                    random={true}
                    shownImages={homeImages}
                />
            </div>
            <a
                className="text-white sm:text-sm md:text-lg w-fit mx-auto my-8 flex items-center gap-4 "
                href={"/nossosprodutos"}
            >
                <p>Ver todos</p>
                <IoIosArrowForward className="animate-side-bounce sm:text-lg md:text-xl text-red-600" />
            </a>
            <Footer theme="light" />
        </div>
    );
}
