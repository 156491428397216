import React, { useEffect } from "react";
import OndeEstamosScreen from "screens/OndeEstamosScreen";

export default function OndeEstamos() {
    useEffect(() => {
        document.title = "Unicapas | Onde estamos";
    }, []);

    return <OndeEstamosScreen />;
}
