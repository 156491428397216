import HomeScreen from "screens/HomeScreen";
import React, { useEffect } from "react";

export default function Home() {
    useEffect(() => {
        document.title = "Unicapas | Home";
    }, []);

    return <HomeScreen />;
}
