import Navbar from "components/Navbar";
import React from "react";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "shadcnuiComponents/ui/sheet";
import { AiOutlineMenu } from "react-icons/ai";

export default function SideMenu({ className }: { className?: string }) {
    return (
        <Sheet>
            <SheetTrigger asChild className={className}>
                <button>
                    <AiOutlineMenu />
                </button>
            </SheetTrigger>
            <SheetContent className="flex flex-col gap-6">
                <SheetHeader className="m">
                    <SheetTitle>Menu</SheetTitle>
                </SheetHeader>
                <SheetDescription>
                    <Navbar vertical={true} className="ml-auto text-right" />
                </SheetDescription>
            </SheetContent>
        </Sheet>
    );
}
