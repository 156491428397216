import Footer from "components/Footer";
import Title from "components/Title";
import React from "react";
import { FiExternalLink } from "react-icons/fi";
import { FaLocationDot, FaRegCopy } from "react-icons/fa6";

export default function OndeEstamosScreen() {
    return (
        <div className="mt-[20vh] w-screen h-[70vh] animate-appear duration-300">
            <Title className="text-black my-8 sm:mt-4 md:mt-6 sm:text-2xl md:text-3xl">
                Nossa localização
            </Title>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3981.3559180576663!2d-38.53355962584738!3d-3.732370043190627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c749aa2ae08e2f%3A0x34049ac5825e2681!2sUnicapas!5e0!3m2!1spt-BR!2sbr!4v1698267602446!5m2!1spt-BR!2sbr"
                className="w-4/5 h-[70vh] mx-auto border-none"
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            />
            <div className="flex flex-col lg:flex-row justify-center items-center w-4/5 h-auto mx-auto pt-8 my-8 gap-8 border-t-2 border-neutral-300">
                <img
                    src="images/OndeEstamos/fachada.jpg"
                    alt="Fachada da Unicapas"
                    className="rounded-xl shadow-md shadow-neutral-400"
                />
                <div className="flex flex-col gap-2 sm:border-t-2 lg:border-0 lg:border-l-2 border-neutral-400 sm:py-4 md:px-4 justify-center">
                    <h2 className="font-bold">
                        Nossa loja está localizada no Centro de Fortaleza!
                    </h2>
                    <div className="flex gap-2 items-center relative">
                        <FaLocationDot className="text-red-600" />
                        <div className="flex items-center gap-3">
                            <h3 className="sm:text-sm md:text-md">
                                Rua General Clarindo de Queiroz, 770
                            </h3>
                            <div>
                                <FaRegCopy
                                    className="ml-auto hover:cursor-pointer sm:text-sm md:text-md"
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            "Rua General Clarindo de Queiroz, 770"
                                        );
                                        alert(
                                            "Endereço copiado para a área de transferência!"
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <a
                        href="https://maps.app.goo.gl/pLRVngsVvdwNyga17"
                        target="_blank"
                        className="text-sm underline flex items-center gap-2 w-fit"
                    >
                        <p>Ver no Google Maps</p>
                        <FiExternalLink className="text-lg" />
                    </a>
                </div>
            </div>
            <Footer theme="dark" />
        </div>
    );
}
