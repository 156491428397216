import { Swiper as Sw, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { ImagesType } from "assets/images";

export default function Swiper({
    className,
    shownImages,
    random = false,
    slidesPerView,
    spaceBetween,
}: {
    className?: string;
    shownImages: ImagesType[];
    random?: boolean;
    slidesPerView: number;
    spaceBetween: number;
}) {
    function shuffle(arr: ImagesType[]) {
        if (random) {
            let newArr = arr;

            for (var i = newArr.length - 1; i > 0; i--) {
                var j = Math.floor(Math.random() * (i + 1));
                var aux = newArr[i];
                newArr[i] = newArr[j];
                newArr[j] = aux;
            }

            return newArr;
        }
        return arr;
    }

    return (
        <Sw
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            pagination={window.outerWidth > 640 ? { clickable: true } : false}
            className={className}
        >
            <div className={`m-8`}>
                {shuffle(shownImages).map((img) => (
                    <SwiperSlide key={img.id}>
                        {img.page ? (
                            <a href="/nossosprodutos">
                                <img
                                    src={img.path}
                                    alt={img.path}
                                    className="rounded-md shadow-md shadow-neutral-800 hover:cursor-pointer sm:w-full md:w-[90%]"
                                />
                                <p className="text-white">{img.label}</p>
                            </a>
                        ) : (
                            <img
                                src={img.path}
                                alt={img.path}
                                className="rounded-md shadow-md shadow-neutral-800 hover:cursor-pointer sm:w-full md:w-[90%]"
                            />
                        )}
                    </SwiperSlide>
                ))}
            </div>
        </Sw>
    );
}
