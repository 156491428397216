import Home from "pages/Home";
import Banner from "components/Banner";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import OndeEstamos from "pages/OndeEstamos";
import Contatos from "pages/Contatos";
import Produtos from "pages/Produtos";
import { SearchContextProvider } from "context/searchContext";
import Page404 from "pages/404";

export default function AppRouter() {
    return (
        <BrowserRouter>
            <SearchContextProvider>
                <Banner />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/ondeestamos" element={<OndeEstamos />} />
                    <Route path="/contatos" element={<Contatos />} />
                    <Route path="/nossosprodutos" element={<Produtos />} />
                    <Route path="/*" element={<Page404 />} />
                </Routes>
            </SearchContextProvider>
        </BrowserRouter>
    );
}
