import { MainImagesType } from "assets/images";
import Swiper from "components/Swiper";
import { FaCircle } from "react-icons/fa6";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "shadcnuiComponents/ui/accordion";

export default function Card({ item }: { item: MainImagesType }) {
    return (
        <div className="w-11/12 mx-auto flex flex-col gap-4 mb-8 rounded-md shadow-lg shadow-neutral-300 p-6">
            <div className="w-full mx-auto my-4">
                <Swiper
                    slidesPerView={1.2}
                    spaceBetween={window.outerWidth > 640 ? 1 : 10}
                    shownImages={item.images}
                    className="w-full rounded-lg"
                />
            </div>
            <div className="flex flex-col items-center gap-4">
                <p className="font-medium border-b-[1px] border-neutral-400 sm:text-md md:text-lg w-4/5 mx-auto pb-3">
                    {item.label}
                </p>
                {item.description && (
                    <Accordion
                        type="single"
                        collapsible
                        className="w-4/5 mx-auto"
                    >
                        <AccordionItem value={`${item.id}`}>
                            <AccordionTrigger>
                                <p className="text-black">
                                    {item.description?.title}
                                </p>
                            </AccordionTrigger>
                            <AccordionContent>
                                <p className="text-black">
                                    {item.description?.content}
                                </p>
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                )}
                <div className="flex gap-1 my-4">
                    {item.colors.map((color, index) => (
                        <FaCircle
                            key={index}
                            color={color}
                            className={`rounded-full ${
                                color === "#ffffff" &&
                                "border-[1px] border-black"
                            } h-auto`}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
