import { Search } from "components/Banner";
import { createContext, useState, useContext } from "react";

const SearchContext = createContext<any>("");
SearchContext.displayName = "Busca";

export function SearchContextProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const [search, setSearch] = useState("");

    return (
        <SearchContext.Provider value={{ search, setSearch }}>
            {children}
        </SearchContext.Provider>
    );
}

export const useSearchContext = () => {
    const { search, setSearch } = useContext(SearchContext);

    function changeSearch(value: Search) {
        setSearch(value.search);
    }

    return {
        changeSearch,
        search,
    };
};
