import React, { useEffect } from "react";
import {
    NavigationMenu,
    NavigationMenuList,
} from "shadcnuiComponents/ui/navigation-menu";
import NavbarItem from "./NavbarItem";

export default function Navbar({
    className,
    vertical = false,
}: {
    className?: string;
    vertical?: boolean;
}) {
    const pages = [
        {
            id: 1,
            name: "Home",
            path: "/",
        },
        {
            id: 2,
            name: "Nossos produtos",
            path: "/nossosprodutos",
        },
        {
            id: 3,
            name: "Onde estamos",
            path: "/ondeestamos",
        },
        {
            id: 4,
            name: "Contatos",
            path: "/contatos",
        },
    ];

    return (
        <>
            <NavigationMenu className={className}>
                <NavigationMenuList
                    className={vertical ? "flex flex-col items-end" : ""}
                >
                    {pages.map((page) => (
                        <NavbarItem
                            key={page.id}
                            {...page}
                            active={window.location.pathname === page.path}
                            className={vertical ? "w-fit" : ""}
                        />
                    ))}
                </NavigationMenuList>
            </NavigationMenu>
        </>
    );
}
