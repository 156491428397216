export type ImagesType = {
    id?: number,
    path: string,
    page?: string,
    label?: string,
}

export const homeImages: ImagesType[] = [
    {
        id: 1,
        path: "images/Home/capa-honda-bege.jpg",
        page: "/produtos/capa-honda-bege",
    },
    {
        id: 2,
        path: "images/Home/capa-honda-vermelha.jpg",
        page: "/produtos/capa-honda-vermelha"
    },
    {
        id: 3,
        path: "images/Home/capa-red-bull-azul.jpg",
        page: "/produtos/capa-red-bull-azul"
    },
    {
        id: 4,
        path: "images/Home/capa-red-bull-azul-moto.jpg",
        page: "/produtos/capa-red-bull-azul"
    },
    {
        id: 5,
        path: "images/Home/capa-xre-300.jpg",
        page: "/produtos/capa-xre-300"
    },
    {
        id: 6,
        path: "images/Home/tres-capas.jpg",
        page: "/produtos"
    },
    // {
    //     id: 3,
    //     path: "images/Home/capa-penelope.jpg",
    //     page: "/produtos/capa-penelope"
    // },
]

export type MainImagesType = {
    id: number,
    images: ImagesType[],
    label: string,
    description?: {
        title: string,
        content: string
    },
    colors: string[],
    category: string,
    options: number
}

export const mainImages: MainImagesType[] = [
    {
        id: 1,
        images: [
            {path:'/images/NossosProdutos/capa-antiderrapante.jpg'},
            {path:'/images/NossosProdutos/capa-antiderrapante-zoom.jpg'}
        ],
        label: 'Capa Antiderrapante - Cor preta',
        colors: [
            '#000000'
        ],
        category: 'antiderrapante',
        options: 1
    },{
        id: 2,
        images: [
            {path:'/images/NossosProdutos/capa-textura-hexagonal.jpg'},
            {path:'/images/NossosProdutos/capa-textura-hexagonal-zoom.jpg'}
        ],
        label: 'Capa com textura hexagonal - Cores preta e vermelha',
        colors: [
            '#000000',
            '#ff0000'
        ],
        category: 'esportiva',
        options: 2
    },{
        id: 3,
        images: [
            {path:'/images/NossosProdutos/capas-confeccionadas.jpg'},
            {path:'/images/NossosProdutos/capas-confeccionadas-zoom.jpg'}
        ],
        label: 'Capas confeccionadas - Cor preta',
        colors: [
            '#000000'
        ],
        category: 'confeccionada',
        options: 2
    },{
        id: 4,
        images: [
            {path:'/images/NossosProdutos/capas-crf.jpg'},
            {path:'/images/NossosProdutos/capas-crf-zoom.jpg'}
        ],
        label: 'Capas CRF - Cores variadas',
        description: {
            title: 'Cores disponíveis',
            content: 'Preta c/ Branco, Preta c/ Azul, Preta c/ Vermelho, Vermelha c/ Branco e Preta c/ Logo'
        },
        colors: [
            '#000000',
            "#ffffff",
            "#0000ff",
            '#ff0000'
        ],
        category: 'esportiva',
        options: 5
    },{
        id: 5,
        images: [
            {path:'/images/NossosProdutos/capas-esportivas-variadas-1.jpg'},
            {path:'/images/NossosProdutos/capas-esportivas-variadas-1-zoom.jpg'}
        ],
        label: 'Capas esportivas com Logo - Modelos variados',
        description: {
            title: 'Logos disponíveis',
            content: 'Titan, Flamengo, CB300R, São Paulo e Honda'
        },
        colors: [
            '#000000',
            "#e0c76c"
        ],
        category: 'esportiva',
        options: 5
    },{
        id: 6,
        images: [
            {path:'/images/NossosProdutos/capas-esportivas-variadas-2.jpg'},
            {path:'/images/NossosProdutos/capas-esportivas-variadas-2-zoom.jpg'}
        ],
        label: 'Capas esportivas com Logo - Modelos variados',
        description: {
            title: 'Logos disponíveis',
            content: 'XRE300, Yamaha, Uber, Ceará e Honda'
        },
        colors: [
            '#000000',
        ],
        category: 'esportiva',
        options: 5
    },{
        id: 7,
        images: [
            {path:'/images/NossosProdutos/capas-mod-original-cores.jpg'},
            {path:'/images/NossosProdutos/capas-mod-original-cores-zoom.jpg'}
        ],
        label: 'Capas modelo original - Cores variadas',
        description: {
            title: 'Cores disponíveis',
            content: 'Branca, Rosa, Rosa claro, Verde, Preto, Vermelho, Cinza, Marrom, Amarelo e Azul'
        },
        colors: [
            '#ffffff',
            "#ff0059",
            "#fca4b9",
            "#00ff00",
            "#000000",
            "#ff0000",
            "#9e9e9e",
            "#a17c5c",
            "#fcfc05",
            "#0000ff"
        ],
        category: 'original',
        options: 10
    },{
        id: 8,
        images: [
            {path:'/images/NossosProdutos/capas-originais-lisas.jpg'},
            {path:'/images/NossosProdutos/capas-originais-lisas-zoom.jpg'}
        ],
        label: 'Capas originais lisas - Cores Preta e Azul marinho',
        colors: [
            '#000000',
            "#171b69"
        ],
        category: 'original',
        options: 2
    },{
        id: 9,
        images: [
            {path:'/images/NossosProdutos/original-bros-2023.jpg'},
            {path:'/images/NossosProdutos/original-bros-2023-zoom.jpg'}
        ],
        label: 'Capa original Bros 2023 - Cor preta',
        colors: [
            '#000000'
        ],
        category: 'original',
        options: 1
    },
]
