import React from "react";

export default function Title({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) {
    return (
        <div className={`font-bold text-center ${className}`}>{children}</div>
    );
}
