import React, { useEffect } from "react";
import ContatosScreen from "screens/ContatosScreen";

export default function Contatos() {
    useEffect(() => {
        document.title = "Unicapas | Contatos";
    }, []);

    return <ContatosScreen />;
}
