import Container404 from "components/Container404";
import Footer from "components/Footer";
import React from "react";

export default function Screen404() {
    return (
        <div className="mt-[20vh] px-8">
            <div className="h-[70vh] flex items-center justify-center">
                <Container404 />
            </div>
            <Footer theme="dark" />
        </div>
    );
}
