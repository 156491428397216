import Navbar from "components/Navbar";
import SideMenu from "components/SideMenu";
import { useSearchContext } from "context/searchContext";
import { SubmitHandler, useForm } from "react-hook-form";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Input } from "shadcnuiComponents/ui/input";

export type Search = {
    search?: string;
};

export default function Banner() {
    const { register, handleSubmit, reset } = useForm();
    const { changeSearch } = useSearchContext();
    const navigate = useNavigate();

    const onSubmit: SubmitHandler<Search> = (data) => {
        changeSearch(data);
        navigate(`/nossosprodutos`);
        reset();
    };

    return (
        <div className="flex flex-wrap items-center justify-evenly rounded-b-[24px] shadow-lg px-4 bg-white z-50 fixed top-0 w-screen h-[15vh]">
            <a href="/" className="h-full">
                <img
                    src="images/tab-logo.webp"
                    alt="Logo Unicapas"
                    className="h-full w-auto mx-auto"
                />
            </a>
            <Navbar className="hidden md:inline-block scale-90" />
            <form
                className="flex relative sm:w-2/5 md:w-1/5 h-fit shadow-md"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Input
                    placeholder="Pesquisar..."
                    {...register("search", { required: true })}
                    className="pr-10"
                />
                <button
                    type="submit"
                    className="bg-transparent h-full absolute right-0 "
                >
                    <BsSearch className="mr-4" />
                </button>
            </form>
            <SideMenu className="inline-block md:hidden" />
        </div>
    );
}
