import Title from "components/Title";
import React from "react";
import { MdOutlineSearchOff } from "react-icons/md";

export default function Container404() {
    return (
        <div>
            <Title className="flex gap-4 items-center text-xl md:text-2xl font-semibold w-4/5 mx-auto">
                <MdOutlineSearchOff className="text-3xl" />
                <p>Não encontramos nenhum resultado...</p>
            </Title>
            <div className="w-fit mx-auto mt-4">
                <p className="text-lg">Recomendamos que tente:</p>
                <ul className="text-sm">
                    <li>- Alterar a busca</li>
                    <li>- Alterar o filtro</li>
                    <li>- Digitar uma URL válida</li>
                </ul>
            </div>
        </div>
    );
}
