import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
    NavigationMenuItem,
    NavigationMenuLink,
} from "shadcnuiComponents/ui/navigation-menu";

export default function NavbarItem({
    id,
    name,
    path,
    className,
    active,
}: {
    id: number;
    name: string;
    path: string;
    className?: string;
    active: boolean;
}) {
    return (
        <NavigationMenuItem
            key={id}
            className={`
                hover:bg-zinc-100  
                duration-200 
                px-4 
                py-2 
                rounded-md 
                text-center
                ${active ? "bg-zinc-200" : ""}
                ${className}
            `}
        >
            <a href={path}>
                <NavigationMenuLink>{name}</NavigationMenuLink>
            </a>
        </NavigationMenuItem>
    );
}
