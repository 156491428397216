import React, { useEffect } from "react";
import Screen404 from "screens/404Screen";

export default function Page404() {
    useEffect(() => {
        document.title = "Unicapas | Página não encontrada";
    }, []);

    return <Screen404 />;
}
