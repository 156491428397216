import classNames from "classnames";
import React from "react";

export default function Footer({
    theme = "light",
}: {
    theme?: "light" | "dark";
}) {
    const footerClasses = classNames(
        "w-screen text-center sm:text-xs md:text-sm h-[10vh] font-semibold flex items-center justify-center",
        { "text-neutral-600": theme === "light" },
        { "text-black": theme === "dark" }
    );

    return (
        <footer className={footerClasses}>
            <p>Todos os direitos reservados &copy; 2023 Unicapas Oficial</p>
        </footer>
    );
}
